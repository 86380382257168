.lang-ordinary {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 56px;
    height: 21px;
    right: 15px;
    margin-left: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2c507b;
    cursor: pointer;
  }
  .lang-image {
    width: 24px;
    height: 19px;
    margin: 0;
    margin-right: 5px;
  }
  
  .lang-choose{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 0px;
    position: absolute;
    z-index: 10;
    right: 15px;
    width: 84px;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
  
  .lang-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    gap: 8px;
    height: 25px;
    width: calc(100% - 8px);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #2C507B;
    cursor: pointer;
  }
  .lang-items:hover {
    background: #F3F7FF
  }