.header-div{
    position: absolute;
    top: 20px;
    left: 4vw;
    z-index: 15;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: calc(92vw - 20px);
    border-radius: 15px;
    height: 60px;
    background-color: rgb(241, 241, 236);
}

.header-div-main {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 60px;
    padding: 10px 0;
    background-color: rgba(196,196,190, 0.2);
    box-shadow: 0px 5px 5px rgba(168, 167, 167, 0.692);
}

.logo-div{
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 50%;
}

.logo-image{
    width: 70px;
    height: 70px;
    margin-right: 10px;
    margin-left: 15px;
}

.name-div{
    display: block;
    align-self: center;
}

.name-h{
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    color: #2c507b;
    margin: 0
}

.second-h2{
    font-weight: 400;
    font-size: 17px;
}

.search-lang-end{
    display: flex;
    align-items: end;
}

.search-lang-div{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 600px){
    .header-div{
        height: 80px;
    }

    .logo-image{
        min-width: 50px;
        max-width: 50px;
        height: 50px;
    }

    .name-div{
        display: none;
    }
}
