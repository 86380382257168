.shearch-div{
    display: flex;
    align-items: center;
    margin-right: 15px;
    height: 25px;
    box-sizing: border-box;
    border-bottom: 0px solid #2c507b;
    transition: border-bottom ease-in 0.5s;
}

.search-div-border{
    border-bottom: 1px solid #2c507b;
}

.search-input-hide{
    width: 0;
    transition: width ease-in 0.5s;
    background: transparent;
    outline: none;
    border: none;
    height: 25px;
}

.search-input{
    background: transparent;
    outline: none;
    border: none;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    color: #2c507b;
    width: 200px;
    transition: width ease-in 0.5s;
}

.search-input::placeholder, .search-input-hide::placeholder{
    font-size: 20px;
    font-weight:lighter;
    font-style: italic;
    color: #2c507b;
}

.search-icon{
    color: #2c507b;
    width: 20px; 
    cursor: pointer;   
}


@media (max-width: 600px){
    .search-input{
        width: 120px;
        font-size: 12px;
    }

    .search-input::placeholder, .search-input-hide::placeholder{
        font-size: 14px;
    }
}